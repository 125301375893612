<template>
  <div class="wolman">
    <div class="wolman__banner">
      <div class="content">
        <div class="wolman__banner-text">
          <div class="wolman__banner-title">Инфантильная форма ДЛКЛ</div>
          <div class="wolman__banner-subtitle">болезнь Вольмана</div>
          <div class="wolman__banner-description">
            Болезнь Вольмана (БВ) — это быстро прогрессирующее наследственное
            заболевание детей первых недель жизни, ассоциированное со 100%
            летальностью в отсутствие своевременно установленного диагноза<span
              ><sup>1</sup><sup>2</sup></span
            >
          </div>
          <img
            class="d-none d-xl-block"
            :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/wolman/1.png 2x`"
          />
          <img
            class="d-xl-none"
            :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/wolman/1_mob.png 2x`"
          />
        </div>
      </div>
    </div>
    <div class="wolman__laboratory">
      <div class="content">
        <div class="wolman__laboratory-row">
          <div class="wolman__laboratory-title">
            Клинические<br />
            и лабораторные
            <br />проявления<sup>1</sup>
          </div>
          <div class="wolman__laboratory-text">
            <div class="wolman__laboratory-description">
              Наличие болезни Вольмана следует предположить у ребенка первых
              месяцев жизни с постоянной рвотой или диареей, отставанием в
              развитии, гепатоспленомегалией и синдромом мальабсорбции.
              Увеличенные надпочечники с признаками кальцификации
              являются непосредственным ключом к постановке диагноза
            </div>
            <div class="wolman__laboratory-list">
              <div class="wolman__laboratory-list-item">
                Это мультисистемное заболевание, при которым симптомы не
                являются специфичными
              </div>
              <div class="wolman__laboratory-list-item">
                Инфантильная форма ДЛКЛ (также известная как болезнь Вольмана)
                манифестирует в первые недели жизни
              </div>
            </div>
          </div>
        </div>
        <img
          class="d-none d-xl-block"
          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/wolman/2.jpg 2x`"
        />
        <img
          class="d-xl-none"
          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/wolman/2_mob.jpg 2x`"
        />
      </div>
    </div>
    <div class="wolman__organs">
      <div class="content">
        <div class="wolman__organs-row">
          <div class="wolman__organs-col">
            <div class="wolman__organs-item">
              <div class="wolman__organs-item-icon">
                <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/wolman/icon1.svg`" />
              </div>
              <div class="wolman__organs-item-text">
                <div class="wolman__organs-item-title">Печень<sup>1</sup></div>
                <div class="wolman__organs-item-list">
                  <div class="wolman__organs-item-list-item">Гепатомегалия</div>
                  <div class="wolman__organs-item-list-item">
                    Повышение АЛТ, АСТ
                  </div>
                  <div class="wolman__organs-item-list-item">
                    Печеночная недостаточность
                  </div>
                </div>
              </div>
            </div>
            <div class="wolman__organs-item">
              <div class="wolman__organs-item-icon">
                <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/wolman/icon2.svg`" />
              </div>
              <div class="wolman__organs-item-text">
                <div class="wolman__organs-item-title">
                  Желудочно-кишечный тракт<sup>1</sup>
                </div>
                <div class="wolman__organs-item-list">
                  <div class="wolman__organs-item-list-item">
                    Срыгивание, рвота
                  </div>
                  <div class="wolman__organs-item-list-item">
                    Нарушение вскармливания
                  </div>
                  <div class="wolman__organs-item-list-item">Диарея</div>
                </div>
              </div>
            </div>
          </div>
          <div class="wolman__organs-col">
            <div class="wolman__organs-item">
              <div class="wolman__organs-item-icon">
                <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/wolman/icon3.svg`" />
              </div>
              <div class="wolman__organs-item-text">
                <div class="wolman__organs-item-title">
                  Селезенка<sup>1</sup>
                </div>
                <div class="wolman__organs-item-list">
                  <div class="wolman__organs-item-list-item">Спленомегалия</div>
                  <div class="wolman__organs-item-list-item">Анемия</div>
                  <div class="wolman__organs-item-list-item">
                    Тромбоцитопения
                  </div>
                </div>
              </div>
            </div>
            <div class="wolman__organs-item">
              <div class="wolman__organs-item-icon">
                <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/wolman/icon4.svg`" />
              </div>
              <div class="wolman__organs-item-text">
                <div class="wolman__organs-item-title">
                  Надпочечники<sup>1</sup>
                </div>
                <div class="wolman__organs-item-list">
                  <div class="wolman__organs-item-list-item">
                    Кальцификация надпочечников
                  </div>
                </div>
              </div>
            </div>
            <div class="wolman__organs-item">
              <div class="wolman__organs-item-icon">
                <img :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/wolman/icon5.svg`" />
              </div>
              <div class="wolman__organs-item-text">
                <div class="wolman__organs-item-title">Другое<sup>1</sup></div>
                <div class="wolman__organs-item-list">
                  <div class="wolman__organs-item-list-item">
                    Задержка роста/дефицит массы тела
                  </div>
                  <div class="wolman__organs-item-list-item">Лихорадка</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/wolman/3.png 2x`" />
      </div>
    </div>
    <div class="wolman__symptoms">
      <div class="content">
        <div class="wolman__symptoms-title">
          Симптомы, которые позволят <br />заподозрить болезнь Вольмана<sup>1</sup>
        </div>
        <div class="wolman__symptoms-list">
          <div
            class="wolman__symptoms-list-item wolman__symptoms-list-item_main"
          >
            Повышение объема живота, гепато/спленомегалия
          </div>
          <div
            class="wolman__symptoms-list-item wolman__symptoms-list-item_main"
          >
            АЛТ/АСТ
          </div>
          <div
            class="wolman__symptoms-list-item wolman__symptoms-list-item_main"
          >
            Дефицит массы тела/задержка роста
          </div>
          <div class="wolman__symptoms-list-item">Срыгивания/рвота</div>
          <div class="wolman__symptoms-list-item">Диарея/стеаторея</div>
          <div class="wolman__symptoms-list-item">
            Увеличение и кальцификаты надпочечников (на КТ)
          </div>
          <div class="wolman__symptoms-list-item">
            Интермиттирующая лихорадка
          </div>
          <div class="wolman__symptoms-list-item">Анемия, тромбоцитопения</div>
          <div class="wolman__symptoms-list-item">Повышение ЛДГ, ферритина</div>
          <div class="wolman__symptoms-list-item">
            Повышение уровня общего холестерина
          </div>
          <div class="wolman__symptoms-list-item">ЛПНП, ЛПВП, ТГ</div>
        </div>
        <img :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/wolman/4.jpg 2x`" />
      </div>
    </div>
    <div class="wolman__clinical-case">
      <div class="content">
        <div class="wolman__clinical-case-row">
          <div class="wolman__clinical-case-img-block">
            <img :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/wolman/5.jpg 2x`" />
            <div class="wolman__clinical-case-title">
              Клинический <br />случай<sup>3</sup>
            </div>
            <div class="wolman__clinical-case-subtitle">мальчик, 2 мес</div>
            <div class="wolman__clinical-case-list-title">
              Медицинский анамнез
            </div>
            <div class="wolman__clinical-case-list">
              <div class="wolman__clinical-case-list-item">
                Наследственный анамнез б/о
              </div>
              <div class="wolman__clinical-case-list-item">
                Роды в срок 36 нед
              </div>
              <div class="wolman__clinical-case-list-item">
                Состояние удовлетворительное
              </div>
              <div class="wolman__clinical-case-list-item">
                Вес 2670 г, рост 49 см
              </div>
              <div class="wolman__clinical-case-list-item">Шкала Апгар 8/8</div>
            </div>
            <div class="wolman__clinical-case-description">
              При БВ важно своевременно <br />поставить диагноз
            </div>
          </div>
          <div class="wolman__clinical-case-dates">
            <div class="wolman__clinical-case-date-block">
              <div class="wolman__clinical-case-date-date">2 мес. 11 дн.</div>
              <div class="wolman__clinical-case-date-title">
                Первое обращение
              </div>
              <div class="wolman__clinical-case-date-list">
                <div class="wolman__clinical-case-date-list-item">
                  Лихорадка до 38,9 °С
                </div>
                <div class="wolman__clinical-case-date-list-item">
                  Срыгивание
                </div>
                <div class="wolman__clinical-case-date-list-item">
                  Железодефицитная анемия
                </div>
                <div class="wolman__clinical-case-date-list-item">
                  Недостаточная прибавка веса
                </div>
              </div>
            </div>
            <div class="wolman__clinical-case-date-block">
              <div class="wolman__clinical-case-date-date">2 мес. 18 дн.</div>
              <div class="wolman__clinical-case-date-title">
                Госпитализация, осмотр
              </div>
              <div class="wolman__clinical-case-date-list">
                <div class="wolman__clinical-case-date-list-item">
                  Тяжелое состояние, интоксикация, недостаточного питания
                </div>
                <div class="wolman__clinical-case-date-list-item">
                  Кожные покровы желтушные
                </div>
                <div class="wolman__clinical-case-date-list-item">
                  Живот увеличен в размерах
                </div>
                <div class="wolman__clinical-case-date-list-item">
                  Печень +5 см, селезенка +8 см
                </div>
                <div class="wolman__clinical-case-date-list-item">
                  Мышечный тонус снижен
                </div>
              </div>
              <div class="wolman__clinical-case-date-title">
                Госпитализация, обследование
              </div>
              <div class="wolman__clinical-case-date-list">
                <div class="wolman__clinical-case-date-list-item">Анемия</div>
                <div class="wolman__clinical-case-date-list-item">
                  Тромбоцитопения
                </div>
                <div class="wolman__clinical-case-date-list-item">
                  Гипопротеинемия
                </div>
                <div class="wolman__clinical-case-date-list-item">
                  Выраженный синдром цитолиза
                </div>
                <div class="wolman__clinical-case-date-list-item">
                  КТ: кальцинаты надпочечников
                </div>
              </div>
            </div>
            <div class="wolman__clinical-case-date-block">
              <div class="wolman__clinical-case-date-date">2 мес. 26 дн.</div>
              <div class="wolman__clinical-case-date-title">ДЛКЛ?</div>
              <div class="wolman__clinical-case-date-list">
                <div class="wolman__clinical-case-date-list-item">
                  Гепатоспленомегалия
                </div>
                <div class="wolman__clinical-case-date-list-item">
                  Выраженный синдром цитолиза
                </div>
                <div class="wolman__clinical-case-date-list-item">Анемия</div>
                <div class="wolman__clinical-case-date-list-item">
                  Лихорадка
                </div>
                <div class="wolman__clinical-case-date-list-item">
                  Кальцинаты надпочечников
                </div>
                <div class="wolman__clinical-case-date-list-item">
                  Кровь направлена на определение активности лизосомной кислой
                  лимпазы
                </div>
              </div>
            </div>
            <div class="wolman__clinical-case-date-block">
              <div class="wolman__clinical-case-date-date">3 мес. 24 дн.</div>
              <div
                class="wolman__clinical-case-date-title"
                style="color: #78154f"
              >
                Пациент погиб от полиорганной недостаточности. Диагноз ДЛКЛ
                установлен посмертно
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wolman__sources">
      <div class="content">
        <ol class="wolman__sources-list">
          <li>
            Другие нарушения накопления липидов (Дефицитлизосомой кислой
            липазы), 2023 https://cr.minzdrav.gov.ru/recomend/354_2 Дата
            обращения: 17.01.2025
          </li>
          <li>Jones S, et al. Genet Med. 2016;18(3):452-458.4</li>
          <li>
            Адаптировано из: Агеева Н.В., Агапова И.А., Амелина Е.Л. и др.
            Прогрессирующее заболевание печени: дефицит лизосомной кислой липазы
            (клинические наблюдения) // РМЖ. 2018. No 5(II). С. 96–103.
          </li>
        </ol>
        <div class="wolman__sources-shorts">
          <div class="wolman__sources-short">
            <div><b>АЛТ </b> – аланинаминотрансфераза;</div>
            <div><b>АСТ </b>– аспартатаминотрансфераза;</div>
          </div>
          <div class="wolman__sources-short">
            <div><b>КТ</b> - компьютерная томография;</div>
            <div><b>ЛДГ</b> – лактатдегидрогеназа;</div>
          </div>
          <div class="wolman__sources-short">
            <div><b>ЛПНП</b> – липопротеины низкой плотности;</div>
            <div><b>ЛПВП</b> – липопротеины высокой плотности;</div>
          </div>
          <div class="wolman__sources-short">
            <div><b>ТГ</b> – триглицериды;</div>
            <div><b>ДЛКЛ</b>– дефицит лизосомной кислой липазы.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Wolman",
};
</script>

<style lang="scss" scoped>
.wolman {
  &__banner {
    overflow: hidden;
    padding: 127px 0;
    background-color: #78154f;
    @media screen and (max-width: 1220px) {
      padding: 40px 0;
    }

    &-text {
      position: relative;
      width: 50%;
      @media screen and (max-width: 1220px) {
        width: 100%;
      }

      & > img {
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
        @media screen and (max-width: 1220px) {
          margin-top: 24px;
          position: static;
          transform: none;
          max-width: 100%;
        }
      }
    }

    &-title {
      margin-bottom: 12px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 56px;
      line-height: 120%;
      color: #ffffff;
      @media screen and (max-width: 1220px) {
        font-size: 42px;
      }
      @media screen and (max-width: 767px) {
        font-size: 36px;
      }
    }
    &-subtitle {
      margin-bottom: 48px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 120%;
      color: #ffcd78;
      @media screen and (max-width: 1220px) {
        font-size: 28px;
      }
      @media screen and (max-width: 767px) {
        font-size: 26px;
        margin-bottom: 16px;
      }
    }

    &-description {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 120%;
      color: #ffffff;
      @media screen and (max-width: 1220px) {
        font-size: 18px;
      }
    }
  }

  &__laboratory {
    padding: 90px 0 160px;
    @media screen and (max-width: 1220px) {
      padding-bottom: 0;
    }
    @media screen and (max-width: 767px) {
      padding: 40px 0 0;
    }
    &-row {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      column-gap: 150px;
      @media screen and (max-width: 1220px) {
        flex-direction: column;
        row-gap: 24px;
      }
    }

    &-title {
      position: relative;
      z-index: 2;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 56px;
      line-height: 120%;
      color: #342d2d;
      flex-shrink: 0;

      & sup {
        background-color: #e6d6de !important;
        color: #342d2d !important;
      }

      @media screen and (max-width: 1220px) {
        font-size: 48px;
      }

      @media screen and (max-width: 767px) {
        font-size: 36px;
      }
    }

    &-text {
      position: relative;
      z-index: 2;
    }

    &-description {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 130%;
      color: #342d2d;

      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }

    &-list {
      &-item {
        margin-top: 36px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 120%;
        color: #342d2d;
        &::before {
          width: 19px;
          height: 19px;
          display: block;
          flex-shrink: 0;
          margin-right: 16px;
          margin-top: 3px;
          background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.54595 3.03777C7.83923 2.10395 9.16077 2.10395 9.45405 3.03777L10.3751 5.97047C10.4731 6.2825 10.7175 6.52689 11.0295 6.62489L13.9622 7.54595C14.8961 7.83923 14.8961 9.16077 13.9622 9.45405L11.0295 10.3751C10.7175 10.4731 10.4731 10.7175 10.3751 11.0295L9.45405 13.9622C9.16077 14.8961 7.83923 14.8961 7.54595 13.9622L6.62489 11.0295C6.52689 10.7175 6.2825 10.4731 5.97047 10.3751L3.03777 9.45405C2.10395 9.16077 2.10395 7.83923 3.03777 7.54595L5.97047 6.62489C6.2825 6.52689 6.52689 6.2825 6.62489 5.97047L7.54595 3.03777Z' fill='%2378154F'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-size: contain;
          content: "";
        }

        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
    }

    & img {
      position: absolute;
      left: 80px;
      bottom: -160px;
      @media screen and (max-width: 1220px) {
        margin-top: 16px;
        position: static;
        max-width: 100%;
      }
    }
  }

  &__organs {
    padding: 150px 0;
    background-color: #fbf9fa;
    @media screen and (max-width: 1220px) {
      padding: 60px 0;
    }

    &-row {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      @media screen and (max-width: 1220px) {
        flex-direction: column;
      }
    }

    &-col {
      width: 36%;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 1220px) {
        width: 100%;
      }
      &:last-child {
        margin-left: auto;
        @media screen and (max-width: 1220px) {
          margin-left: 0;
        }

        & .wolman__organs-item {
          &:nth-child(2) {
            margin-left: 40px;
            @media screen and (max-width: 1220px) {
              margin-left: 0;
            }
          }
        }
      }
    }

    & .content > img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media screen and (max-width: 1220px) {
        position: static;
        margin: 24px auto 0;
        transform: none;
      }
    }

    &-item {
      margin-bottom: 56px;
      @media screen and (max-width: 1220px) {
        margin-bottom: 24px;
      }
      &:not(:first-child) {
        margin-top: auto;
        @media screen and (max-width: 1220px) {
          margin-top: 0;
        }
      }
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      &-icon {
        flex-shrink: 0;
        margin-right: 36px;
        @media screen and (max-width: 1220px) {
          width: 80px;
          height: 80px;
        }

        & > img {
          width: 100%;
        }
      }

      &-title {
        margin-bottom: 16px;
        font-family: "Roboto Slab", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 120%;
        color: #342d2d;

        @media screen and (max-width: 767px) {
          font-size: 20px;
        }

        & sup {
          background-color: #e6d6de !important;
          color: #342d2d !important;
        }
      }

      &-list {
        &-item {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 120%;
          color: #342d2d;
          &::before {
            width: 4px;
            height: 4px;
            flex-shrink: 0;
            margin-right: 8px;
            background-color: currentColor;
            margin-top: 10px;
            display: block;
            content: "";
            border-radius: 50%;
          }

          @media screen and (max-width: 767px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  &__symptoms {
    padding: 70px 0;
    @media screen and (max-width: 1220px) {
      padding: 70px 0 0;
    }

    @media screen and (max-width: 767px) {
      padding: 40px 0 0;
    }

    &-title {
      position: relative;
      z-index: 2;
      margin-bottom: 56px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 56px;
      line-height: 120%;
      color: #342d2d;

      & sup {
        background-color: #e6d6de !important;
        color: #342d2d !important;
      }

      @media screen and (max-width: 767px) {
        margin-bottom: 24px;
        font-size: 34px;
      }
    }

    &-list {
      position: relative;
      z-index: 2;
      &-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        counter-increment: step-counter;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 120%;
        color: #342d2d;
        &:not(:last-child) {
          margin-bottom: 12px;
        }
        &_main {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 120%;
          color: #342d2d;
          @media screen and (max-width: 767px) {
            font-size: 18px;
          }
          &::before {
            width: 48px !important;
            height: 48px !important;
            font-size: 24px !important;
            color: #ffffff !important;
            background-color: #78154f !important;
            @media screen and (max-width: 767px) {
              font-size: 18px !important;
              width: 38px !important;
              height: 38px !important;
            }
          }
        }

        &::before {
          width: 36px;
          height: 36px;
          margin-right: 20px;
          flex-shrink: 0;
          font-family: "Roboto Slab", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 120%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #78154f;
          background-color: rgba(120, 21, 79, 0.07);
          border-radius: 50%;
          content: counter(step-counter);
          @media screen and (max-width: 767px) {
            width: 32px;
            height: 32px;
            font-size: 16px;
          }
        }

        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
    }

    & .content img {
      position: absolute;
      bottom: -70px;
      right: 0;
      @media screen and (max-width: 1220px) {
        position: static;
        margin: 24px auto 0;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }

  &__clinical-case {
    padding: 120px 0 45px;
    background-color: #fdf6fa;
    @media screen and (max-width: 1220px) {
      padding: 60px 0 45px;
    }

    @media screen and (max-width: 767px) {
      padding: 40px 0;
    }

    &-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 100px;
      @media screen and (max-width: 1220px) {
        grid-template-columns: 1fr;
        row-gap: 40px;
      }
    }

    &-img-block {
      padding: 30px 50px;
      position: relative;
      height: 814px;
      border-radius: 32px;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 767px) {
        height: 563px;
      }

      & img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: bottom;
      }
    }

    &-title {
      margin-bottom: 16px;
      position: relative;
      z-index: 2;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 56px;
      line-height: 120%;
      color: #ffffff;
      @media screen and (max-width: 767px) {
        font-size: 36px;
      }
    }

    &-subtitle {
      position: relative;
      z-index: 2;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 120%;
      color: #ffffff;
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }

    &-description {
      position: relative;
      z-index: 2;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 120%;
      color: #ffffff;
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }

    &-list {
      margin-bottom: 24px;
      position: relative;
      z-index: 2;
      &-title {
        margin-bottom: 24px;
        position: relative;
        z-index: 2;
        margin-top: auto;
        font-family: "Roboto Slab", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 120%;
        color: #ffffff;
        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
      }

      &-item {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 120%;
        color: #ffffff;
        @media screen and (max-width: 767px) {
          font-size: 18px;
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &::before {
          width: 17px;
          height: 17px;
          display: block;
          flex-shrink: 0;
          margin-right: 8px;
          margin-top: 3px;
          background-repeat: no-repeat;
          background-size: contain;
          content: "";
          background-image: url("data:image/svg+xml,%3Csvg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.54595 1.53777C5.83923 0.603946 7.16077 0.603948 7.45405 1.53777L8.37511 4.47047C8.47311 4.7825 8.7175 5.02689 9.02953 5.12489L11.9622 6.04595C12.8961 6.33923 12.8961 7.66077 11.9622 7.95405L9.02953 8.87511C8.7175 8.97311 8.47311 9.2175 8.37511 9.52953L7.45405 12.4622C7.16077 13.3961 5.83923 13.3961 5.54595 12.4622L4.62489 9.52953C4.52689 9.2175 4.2825 8.97311 3.97047 8.87511L1.03777 7.95405C0.103946 7.66077 0.103948 6.33923 1.03777 6.04595L3.97047 5.12489C4.2825 5.02689 4.52689 4.7825 4.62489 4.47047L5.54595 1.53777Z' fill='%239E4579'/%3E%3C/svg%3E%0A");
        }
      }
    }

    &-dates {
      padding-top: 30px;
      @media screen and (max-width: 767px) {
        padding-left: 20px;
      }
    }

    &-date {
      &-block {
        position: relative;
        padding-left: 80px;
        @media screen and (max-width: 767px) {
          padding-left: 40px;
        }
        &:not(:last-child) {
          padding-bottom: 50px;
          border-left: 2px solid #78154f;
        }
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(-50%, -50%);
          width: 20px;
          height: 20px;
          background-color: #78154f;
          display: block;
          content: "";
          border-radius: 50%;
          border: 10px solid rgba(120, 21, 79, 0.2);
          -webkit-background-clip: padding-box; /* for Safari */
          background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
          box-shadow: 0 0 1px 10px rgba(120, 21, 79, 0.1);

          @media screen and (max-width: 767px) {
            width: 16px;
            height: 16px;
            box-shadow: 0 0 1px 8px rgba(120, 21, 79, 0.1);
            border: 8px solid rgba(120, 21, 79, 0.2);
          }
        }
      }

      &-date {
        margin-bottom: 16px;
        position: relative;
        transform: translateY(-50%);
        padding: 10px 18px;
        width: fit-content;
        background-color: #78154f;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;
        text-transform: uppercase;
        color: #ffffff;
        border-radius: 99px;
        &::before {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 100%;
          width: 80px;
          height: 2px;
          background-color: inherit;
          display: block;
          content: "";
          @media screen and (max-width: 767px) {
            width: 40px;
          }
        }
      }

      &-title {
        margin-bottom: 16px;
        font-family: "Roboto Slab", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 120%;
        color: #342d2d;

        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
      }

      &-list {
        &:not(:last-child) {
          margin-bottom: 24px;
        }
        &-item {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 120%;
          color: #342d2d;

          @media screen and (max-width: 767px) {
            font-size: 16px;
          }

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          &::before {
            width: 17px;
            height: 17px;
            display: block;
            flex-shrink: 0;
            margin-right: 8px;
            margin-top: 3px;
            background-repeat: no-repeat;
            background-size: contain;
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.54595 1.53777C5.83923 0.603946 7.16077 0.603948 7.45405 1.53777L8.37511 4.47047C8.47311 4.7825 8.7175 5.02689 9.02953 5.12489L11.9622 6.04595C12.8961 6.33923 12.8961 7.66077 11.9622 7.95405L9.02953 8.87511C8.7175 8.97311 8.47311 9.2175 8.37511 9.52953L7.45405 12.4622C7.16077 13.3961 5.83923 13.3961 5.54595 12.4622L4.62489 9.52953C4.52689 9.2175 4.2825 8.97311 3.97047 8.87511L1.03777 7.95405C0.103946 7.66077 0.103948 6.33923 1.03777 6.04595L3.97047 5.12489C4.2825 5.02689 4.52689 4.7825 4.62489 4.47047L5.54595 1.53777Z' fill='%2378154F'/%3E%3C/svg%3E%0A");
          }
        }
      }
    }
  }

  &__sources {
    padding: 60px 0;

    &-list {
      padding-left: 1%;
      margin-bottom: 32px;
      list-style-type: decimal;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #747780;
      @media screen and (max-width: 767px) {
        padding-left: 4%;
      }
    }

    &-shorts {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      column-gap: 50px;
      @media screen and (max-width: 1220px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 32px;
      }

      @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        row-gap: 8px;
      }
    }

    &-short {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      color: #747780;

      & b {
        font-weight: 700;
      }

      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
      }
    }
  }

  & sup {
    margin: 0 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    vertical-align: middle;
    width: 19px;
    height: 19px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    text-align: center;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
  }
}
</style>